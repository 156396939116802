define("ember-svg-jar/inlined/enter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.47 11.521a.5.5 0 000 .708l3.068 3.068a.5.5 0 00.707 0l.177-.177a.5.5 0 000-.707L6.509 12.5h7.158a.5.5 0 00.5-.5V5.5a.5.5 0 00-.5-.5h-.25a.5.5 0 00-.5.5v5.75H6.509l1.913-1.913a.5.5 0 000-.707l-.177-.177a.5.5 0 00-.707 0L4.47 11.521z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});