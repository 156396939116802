define("ember-svg-jar/inlined/bell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.353 6.23C6.72 4.427 8.159 2.917 10 2.917c1.84 0 3.28 1.51 3.647 3.313.508 2.491 1.526 3.97 2.24 4.742.354.384.531.577.495.78-.037.205-.22.298-.589.485-.951.482-2.758 1.096-5.793 1.096s-4.842-.614-5.793-1.096c-.368-.187-.552-.28-.589-.484-.036-.204.141-.397.496-.781.713-.772 1.73-2.251 2.239-4.742zm6.07.25c.48 2.355 1.388 3.93 2.179 4.915-.898.336-2.369.688-4.602.688s-3.704-.352-4.602-.688c.79-.985 1.7-2.56 2.18-4.915C7.86 5.095 8.905 4.167 10 4.167c1.095 0 2.14.928 2.422 2.313z\"/><path d=\"M8.75 15a.417.417 0 00-.417.417 1.667 1.667 0 103.334 0A.417.417 0 0011.25 15h-2.5z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});